// BASIC APP
// 
//import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }
// export default App;

// BASIC APP WITH AMPLIFY/COGNITO AUTHENtiCATION
import React from 'react';
import Amplify from '@aws-amplify/core';
import '@aws-amplify/ui/styles.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React as fast as possible
        </a>
      </header>
    </div>
  );
}

export default withAuthenticator(App, { includeGreetings: false });
// export default App;


// import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
// import { ApolloProvider, useQuery } from '@apollo/react-hooks';
// import ApolloClient, { gql } from 'apollo-boost';

// // Initialize Apollo Client
// const client = new ApolloClient({
//   uri: 'https://yourapi.appsync-api.region.amazonaws.com/graphql', // replace with your AppSync API URL
//   headers: {
//     'x-api-key': 'yourapikey' // replace with your AppSync API Key
//   }
// });

// // Define the getSite query
// const GET_SITE = gql`
//   query GetSite($name: String!) {
//     getSite(name: $name) {
//       name
//       facility_type
//       address
//       city
//       state
//       zip_code
//       projectCount
//     }
//   }
// `;

// // Define the Site component
// const Site = ({ siteName }) => {
//   const { loading, error, data } = useQuery(GET_SITE, {
//     variables: { name: siteName },
//   });

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error :(</p>;

//   const site = data.getSite;
//   return <pre>{JSON.stringify(site, null, 2)}</pre>;
// };

// // Define the App component
// const App = () => {
//   const [siteName, setSiteName] = useState('');

//   const handleSubmit = (event) => {
//     event.preventDefault();
//   };

//   return (
//     <div>
//       <form onSubmit={handleSubmit}>
//         <label>
//           Site Name:
//           <input
//             type="text"
//             value={siteName}
//             onChange={(event) => setSiteName(event.target.value)}
//           />
//         </label>
//         <button type="submit">Submit</button>
//       </form>
//       {siteName && <Site siteName={siteName} />}
//     </div>
//   );
// };

// ReactDOM.render(
//   <ApolloProvider client={client}>
//     <App />
//   </ApolloProvider>,
//   document.getElementById('root')
// );

// export default App;
